<template>
  <v-container>
    <v-row>
        <v-col cols="12">
            <v-card elevation="5" class="px-5 py-6">
                <v-card-title>
                  <h3>Form di cambio : Cambio di adres</h3>  
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3" style="border-right:1px solid #d3d3d3;text-align:center">
                            <v-avatar
                                color="primary"
                                size="97"
                                >
                                <span style="font-size:28px;color:#fff">JG</span>
                                </v-avatar>
                                <p>José Guardia</p>
                                <p>656546564</p>
                        </v-col>
                        <v-col cols="9">
                            <v-row>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-account</v-icon> <p><b>Fam:</b>  Guardia</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-account</v-icon><p><b>Prome Nomber(nan):</b> José</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-phone</v-icon><p><b>Number di telefon :</b> 5547854563</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-email</v-icon> <p><b>Email:</b> jguardia19@gmail.com</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-card-account-details</v-icon><p><b>ID Nummer:</b>8565655654</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-map-marker</v-icon><p><b>Adress:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias expedita distinctio.</p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">
                                    <v-icon large  color="primary" >mdi-account-supervisor</v-icon><p><b>Estado Civil:</b> </p> 
                                </v-col>
                                <v-col cols="3" class="datos d-flex">

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style lang="scss">
    .datos{
        border-bottom: 1px solid #d3d3d3;
    }
</style>